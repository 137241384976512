
import { defineComponent } from 'vue'
import Home from '../components/Home.vue'
import History from '../components/History.vue'
import Products from '../components/Products.vue'
import Contact from '../components/Contact.vue'
import Footer from '../components/Footer.vue'

export default defineComponent({
  components: {
    Home,
    History,
    Products,
    Contact,
    Footer,
  },
  props: {
    section: String,
  },
  data() {
    return {
      sectionName: this.section,
    }
  },
  watch: {
    section() {
      const navBarSize = document.getElementById('navBar')
                ? document.getElementById('navBar')?.clientHeight
                : 64 as any
      if (this.sectionName) {
      var element = this.$refs[this.sectionName] as any
      if (element) {
        element = element.$el
      }
      var top = element.offsetTop - (navBarSize + 20)
      window.scrollTo(0, top)
      }
      this.sectionName = ''
    }
  }
})
