
import { defineComponent } from 'vue'
import IconEmail from '../assets/icons/IconEmail.vue'
import IconFacebook from '../assets/icons/IconFacebook.vue'
import IconPhone from '../assets/icons/IconPhone.vue'
import IconWhatsapp from '../assets/icons/IconWhatsapp.vue'

export default defineComponent({
    components: {
        IconEmail,
        IconFacebook,
        IconPhone,
        IconWhatsapp,
    }
})
