import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/images/coverEs.png'


const _withScopeId = n => (_pushScopeId("data-v-69acf720"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full py-0 bg-red-100 flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "object-cover mx-auto",
      style: _normalizeStyle({maxHeight: _ctx.imageSize}),
      src: _imports_0,
      alt: ""
    }, null, 4)
  ]))
}