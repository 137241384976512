
import IconFincaLogo from './assets/icons/IconFincaLogo.vue'
import IconMenu from './assets/icons/IconMenu.vue'
import IconX from './assets/icons/IconX.vue'
import { defineComponent } from 'vue'
import Home from './components/Home.vue'
import History from './components/History.vue'
import Company from './components/Company.vue'
import Products from './components/Products.vue'
import Contact from './components/Contact.vue'
import HomeEs from './components/HomeEs.vue'
import HistoryEs from './components/HistoryEs.vue'
import CompanyEs from './components/CompanyEs.vue'
import ProductsEs from './components/ProductsEs.vue'
import ContactEs from './components/ContactEs.vue'
import Footer from './components/Footer.vue'
import { Slide } from 'vue3-burger-menu'

export default defineComponent({
  components: {
    IconFincaLogo,
    IconMenu,
    IconX,
    Home,
    History,
    Company,
    Products,
    Contact,
    Footer,
    Slide,
    HomeEs,
    HistoryEs,
    CompanyEs,
    ProductsEs,
    ContactEs,
  },
  data() {
    return {
      isOpen: false,
      isEnglish: true,
      showLanguage: true,
    }
  },
  methods: {
    goto(section) {
      const navBarSize = document.getElementById('navBar')
                ? document.getElementById('navBar')?.clientHeight
                : 64 as any
      if (section) {
      var element = this.$refs[section] as any
      if (element) {
        element = element.$el
      }
      var top = element.offsetTop - (navBarSize + 5)
      window.scrollTo(0, top)
      }
    }
  },
  computed: {
    menuList() {
      if (this.isEnglish) return ['Home', 'Our history', 'The company', 'Products', 'Contact us']
      return ['Inicio', 'Nuestros comienzos', 'La empresa', 'Productos', 'Contacto']
    }
  }
})
