
import { defineComponent } from 'vue'
import IconHeart from '../assets/icons/IconHeart.vue'
import IconNext from '../assets/icons/IconNext.vue'
import IconPrev from '../assets/icons/IconPrev.vue'
import { VueAgile } from 'vue-agile'

export default defineComponent({
    components: {
        IconHeart,
        agile: VueAgile,
        IconNext,
        IconPrev,
    },
    data() {
        return {
            currentIndex: 0,
            myOptions: {
                dots: true,
                slidesToShow: 1,
                centerMode: true,
                responsive: [
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 3,
                        }
                    }
                ]
            },
            pictures: [
                {
                    title: 'Tomate triturado en lata (8kg)',
                    info: `125 latas por pallet.
                            26 ó 28 pallets por camion.`,
                    href: '../assets/images/crushedTomatoes.jpg',
                },
                {
                    title: 'Tomate triturado en sachet (1kg)',
                    info: `10 sachets por caja.
                            80 cajas por pallet.
                            26 ó 28 pallets por camion.`,
                    href: '../assets/images/crushedTomatoesSachet.jpg',
                },
                {
                    title: 'Tomate triturado en sachet (2kg)',
                    info: `4 sachets por caja.
                            125 cajas por pallet.
                            26 ó 28 pallets por camion.`,
                    href: '../assets/images/crushedTomatoesSachet.jpg',
                },
                {
                    title: 'Salsa a base de tomate en sachet (1kg)',
                    info: `10 sachets por caja.
                            80 cajas por pallet.
                            26 ó 28 pallets por camion.`,
                    href: '../assets/images/crushedTomatoesSachet.jpg',
                },
                {
                    title: 'Salsa para pizza en sachet (2kg)',
                    info: `4 sachets por caja.
                            125 cajas por pallet.
                            26 ó 28 pallets por camion.`,
                    href: '../assets/images/crushedTomatoesSachet.jpg',
                },
                {
                    title: 'Pimiento entero en lata (750gr)',
                    info: `12 latas por caja.
                            100 cajas por pallet.
                            26 ó 28 pallets por camion.`,
                    href: '../assets/images/redbellPeppers.jpg',
                },
                {
                    title: 'Alcaparras (100gr)',
                    info: '48 frascos por caja.',
                    href: '../assets/images/cappers.jpg',
                },
                {
                    title: 'Champiñones laminados en lata (850gr)',
                    info: `12 latas por caja.
                            100 cajas por pallet.
                            26 ó 28 pallets por camion.`,
                    href: '../assets/images/slicedMushrooms.jpg',
                },
                {
                    title: 'Champiñones enteros en lata (850gr)',
                    info: `12 latas por caja.
                            100 cajas por pallet.
                            26 ó 28 pallets por camion.`,
                    href: '../assets/images/wholeMushrooms.jpg',
                },
            ]
        }
    },
    methods: {
        updateInfo(event) {
            this.currentIndex = event.currentSlide
        }
    }
})
