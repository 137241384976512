
import { defineComponent } from 'vue'

export default defineComponent({
    computed: {
        year() {
            const year = new Date().getFullYear()
            return year
        },
    }
})
