
import { defineComponent } from 'vue'

export default defineComponent({
    components: {
    },
    computed: {
        imageSize() {
            const navBarSize = document.getElementById('navBar')
                ? document.getElementById('navBar')?.clientHeight
                : 64
            const windowSize = window.innerHeight
            return `${windowSize - (navBarSize as number)}px`
        }
    }
})
