
import { defineComponent } from 'vue'
import IconHeart from '../assets/icons/IconHeart.vue'
import IconNext from '../assets/icons/IconNext.vue'
import IconPrev from '../assets/icons/IconPrev.vue'
import { VueAgile } from 'vue-agile'

export default defineComponent({
    components: {
        IconHeart,
        agile: VueAgile,
        IconNext,
        IconPrev,
    },
    data() {
        return {
            currentIndex: 0,
            myOptions: {
                dots: true,
                slidesToShow: 1,
                centerMode: true,
                responsive: [
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 3,
                        }
                    }
                ]
            },
            pictures: [
                {
                    title: 'Can of crushed tomatoes (8kg)',
                    info: `2 cans per box.
                            1575 boxes per container.
                            3150 cans per container.`,
                    href: '../assets/images/crushedTomatoes.jpg',
                },
                {
                    title: 'Sachet of crushed tomatoes (1kg)',
                    info: `10 sachets per box.
                            2100 boxes per container.
                            21000 sachets per container.`,
                    href: '../assets/images/crushedTomatoesSachet.jpg',
                },
                {
                    title: 'Sachet of crushed tomatoes (2kg)',
                    info: `4 sachets per box.
                            3250 boxes per container.
                            13000 sachets per container.`,
                    href: '../assets/images/crushedTomatoesSachet.jpg',
                },
                {
                    title: 'Sachet of tomato based sauce (1kg)',
                    info: `10 sachets per box.
                            2100 boxes per container.
                            21000 sachets per container.`,
                    href: '../assets/images/crushedTomatoesSachet.jpg',
                },
                {
                    title: 'Sachet of pizza sauce (2kg)',
                    info: `4 sachets per box.
                            3250 boxes per container.
                            13000 sachets per container.`,
                    href: '../assets/images/crushedTomatoesSachet.jpg',
                },
                {
                    title: 'Can of whole redbell peppers (750gr)',
                    info: `12 cans per box.
                            2100 boxes per container.
                            25200 cans per container`,
                    href: '../assets/images/redbellPeppers.jpg',
                },
                {
                    title: 'Jar of capers (100gr)',
                    info: `48 jars per box.
                            2100 boxes per container.
                            201600 jars per container.`,
                    href: '../assets/images/cappers.jpg',
                },
                {
                    title: 'Can of sliced mushrooms (850gr)',
                    info: `12 cans per box.
                            2100 boxes per container.
                            25200 cans per container`,
                    href: '../assets/images/slicedMushrooms.jpg',
                },
                {
                    title: 'Can of whole mushrooms (850gr)',
                    info: `12 cans per box.
                            2100 boxes per container.
                            25200 cans per container`,
                    href: '../assets/images/wholeMushrooms.jpg',
                },
            ]
        }
    },
    methods: {
        updateInfo(event) {
            this.currentIndex = event.currentSlide
        }
    }
})
